<template>
  <Home />
</template>

<script>
import Home from '@/components/Home.vue'
import axios from 'axios';
export default {
  name: 'App',
  components: {
    Home
  },
  mounted() {
    // this.addCodeParameter();
    // this.embedPage()
    this.tiaozhuan()
  },
  methods: {
    addCodeParameter() {
      const url = new URL(window.location.href);
      if (!url.searchParams.has('channelCode')) {
        url.searchParams.set('channelCode', '15');
        window.history.replaceState({}, '', url.toString());
      }
    },
    embedPage() {
      const targetUrl = 'https://douyusm.com';
      // 拼接参数
      const params = new URLSearchParams({ channelCode: 15 });

      // 发送 GET 请求
      axios
        .get(`${targetUrl}?${params.toString()}`)
        .then((response) => {
          // 请求成功，保存数据
          this.data = response.data;
        })
        .catch((error) => {
          // 请求失败，保存错误信息
          this.error = error.message || '请求失败';
        });
    },
    tiaozhuan(){
      // 获取当前 URL 的查询参数
      const urlParams = new URLSearchParams(window.location.search);

      // 检查是否包含 channelCode 参数
      if (!urlParams.has('channelCode')) {
        // 如果没有 channelCode 参数，拼接新的 URL
        const newUrl = `${window.location.origin}${window.location.pathname}?channelCode=15`;

        // 跳转到新的 URL
        window.location.href = newUrl; // 或者使用 window.location.replace(newUrl);
      }
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/app.css";
</style>
